<template>
    <div class="container-fluid footer">
        <div class="row">
            <div class="col-7">URL：www.nsservice.co.jp</div>
            <div class="col-5">TEL：03-5822-3021</div> 
                <!-- 旧電話番号03-6879-9908-->
        </div>
        <!-- 小分辨率显示：d-block d-md-none 大分辨率显示：col-7 d-none d-md-block-->
        <div class="row">

            <div class="col-7 d-none d-md-block">本店：〒101-0025 東京都千代田区神田佐久間町2-18-1
            </div>
            <div class="col-7 d-block d-md-none">本店：〒101-0025 東京都千代田区神田佐久間町2-18-1 秋葉原STNフロント 12F
            </div>
            <div class="col-5">受付：9:30-18:30（土・日・祝祭日除く）</div>
        </div>

        <div class="row  d-none d-md-flex" style="margin-bottom: 15px;">
            <div style=" text-indent:65px">秋葉原STNフロント 12F</div>
        </div>


        <div class="row" style="margin-top: 30px;">
            <div class="col-7"><a href="./01.pdf" target="_blank">個人情報に関する公表文</a></div>
            <div class="col-5"><a href="./02.pdf" target="_blank">個人情報保護方針</a></div>
        </div>
        <div class="copyright">
            Copyright 2018 | All Rights Reserved by Nsservice.
        </div>
    </div>
</template>

<script>
export default {
    methods: {

    }
}
</script>

<style>
.footer {
    bottom: 0px;
    padding: 20px calc(100vw/1920*320);
    height: 250px;
    background: rgba(26, 26, 26, 1);
}

.footer div div {
    font-size: 16px;
    line-height: 40px;
    text-align: left;
    font-family: 'Noto Serif JP',YuGothic, "游ゴシック体", serif;
    font-weight: 400;
    color: rgba(221, 221, 221, 1);

}

.footer div {
    display: flex;
    justify-content: space-between;
    text-align: center;
    font-size: 14px;
    font-family: 'Noto Serif JP',YuGothic, "游ゴシック体", serif;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    line-height: 20px;
}

.copyright {
    margin-top: 20px;
    margin-right: 100px;
    justify-content: center !important;
}

@media (max-width: 767px) {
    .desktop-content {
        display: none;
        /* 在小屏幕上隐藏 */
    }

    .mobile-content {
        display: block;
        /* 在小屏幕上显示 */
    }
}
</style>