import Vue from 'vue'
import VueRouter from 'vue-router'
const Home = () => import('../views/Home.vue')
const Service = () => import('../views/Service.vue')
const Info = () => import('../views/ServiceView/Info.vue')
const Btc = () => import('../views/ServiceView/Btc.vue')
const Creditcard = () => import('../views/ServiceView/Creditcard.vue')
const Develop = () => import('../views/ServiceView/Develop.vue')
const SmartCar = () => import('../views/ServiceView/SmartCar.vue')
const Recruit = () => import('../views/Recruit.vue')
const Policy = () => import('../views/RecruitView/Policy.vue')
const Graduated = () => import('../views/RecruitView/Graduated.vue')
const Social = () => import('../views/RecruitView/Social.vue')
const Company = () => import('../views/Company.vue')
const Abstract = () => import('../views/CompanyView/Abstract.vue')
const Strategy = () => import('../views/CompanyView/Strategy.vue')
const Instance = () => import('../views/CompanyView/Instance.vue')
const Contact = () => import('../views/Contact.vue')


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/service',
    name: 'service',
    component: Service,
    children:[
      {
        path:'',
        name:'info',
        component:Info
      },
      {
        path:'btc',
        name:'btc',
        component:Btc
      },
      {
        path:'creditcard',
        name:'creditcard',
        component:Creditcard
      },
      {
        path:'develop',
        name:'develop',
        component:Develop
      },
      {
        path:'smartcar',
        name:'smartcar',
        component:SmartCar,
      },
    ]
  },
  {
    path: '/recruit',
    name: 'recruit',
    component: Recruit,
    children:[
      {
        path:'',
        name:'policy',
        component:Policy
      },
      {
        path:'graduated',
        name:'graduated',
        component:Graduated
      },
      {
        path:'social',
        name:'social',
        component:Social
      },
    ]
  },
  {
    path: '/company',
    name: 'company',
    component: Company,
    children:[
      {
        path:'',
        name:'abstract',
        component:Abstract
      },
      {
        path:'strategy',
        name:'strategy',
        component:Strategy
      },
      {
        path:'instance',
        name:'instance',
        component:Instance
      },
    ]
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  }
]


const router = new VueRouter({
  // mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
